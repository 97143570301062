import React, {useCallback, useContext, useEffect, useRef, useState} from "react"
import {Field, Form, Formik, FormikHelpers} from "formik"
import PropTypes from "prop-types"
import SearchContext from "../../context/SearchContext"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import {Search, X} from "react-bootstrap-icons"
import Image from "next/image"
import {filterUrl} from "../filter/FilterUrl"
import urlParams from "../../utility/UrlParams"
import dynamic from "next/dynamic"
import Spinner from "../reusable/Spinner"
import {fetchGet} from "../../firebaseAdmin/fetchGet"
import {CollectionName} from "../../constants"
import DataContext from "../../context/DataContext"

interface Values {
 searchTerm: string;
	setIsBackgroundOn?: any
	isBackgroundOn?: boolean
	isBrandsPage?: boolean

}

const DynamicSearchContent = dynamic(() => import("./SearchContent"), {
	loading: () => <div>
		<Spinner />
	</div>
})

const SearchField = ({onStartTyping, onType, onEndTyping, className, setIsBackgroundOn, isBackgroundOn, isBrandsPage}) => {
	const {isOn, setValue, toggle, termValue, setTermValue, value} = useContext(SearchContext)
	const {reactGA} = useContext(AnalyticsContext)
	const {t} = useTranslation("common")
	const [isLoading, setIsLoading] = useState(false)
	const errors = {}
	const touched = {}

	const timeout = useRef(null)
	let onStartTypingSent = false

	const searchField = useRef(null)

	const router = useRouter()

	const handleOnChange = value => {
		if (timeout) {
			clearTimeout(timeout.current)
		}

		if (!onStartTypingSent) {
			onStartTypingSent = true
			onStartTyping()
		}

		timeout.current = setTimeout(() => {
			onStartTypingSent = false
			onEndTyping(value)
		}, 500)

		if (onType) {
			onType(value)
		}

		setValue(value)
		setIsBackgroundOn(value === "")
	}

	const {brandWorld} = useContext(DataContext)
	const handlePressEnterToSaveSearchValue = useCallback(async e => {
		if (e.code === "Enter") {
			setIsLoading(() => true)
			await fetchGet(`/api/search?term=${termValue}&limit=2&offset=0&locale=${router.locale}&source=user-clicked-enter`)
			router.push(isBrandsPage ? `${filterUrl({[urlParams.searchValue]: value}, urlParams.searchValue, value, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` : filterUrl({[urlParams.searchValue]: value, page: "-"}, null, null)).finally(() => {
				setIsLoading(() => false)
				toggle(false)
				setIsBackgroundOn(false)
				setTermValue("")
				if (typeof window !== "undefined") {
					window.scroll({top: 450})
				}
			})
		}
	}, [value])

	useEffect(() => {
		if (router.pathname === "/") {
			setValue(null)
			setTermValue("")
		}

		return (() => {
			setValue(null)
		})
	}, [router])

	useEffect(() => {
		if (!isOn && !isBackgroundOn) {
			document.body.style.overflow = "auto"
		} else if (isOn || isBackgroundOn) {
			document.body.style.overflow = "hidden"
		}

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [termValue || isOn || isBackgroundOn])

	const handleFocus = e => {
		reactGA?.event({
			category: gaCategories.search,
			action: gaEvents.searchTool,
			label: gaEvents.searchTool,
			nonInteraction: false
		})
		setIsBackgroundOn(true)
		if (e.target.value !== "") {
			setTermValue(e.target.value)
			setValue(e.target.value)
		}

		return e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
	}

	return (
		<>
			<Formik
				initialValues={{
					searchTerm: "",
					locale: router.locale
				}}
				onSubmit={(
					values: Values,
					{setSubmitting}: FormikHelpers<Values>
				) => {
					onEndTyping(values.searchTerm)

					// Log event
					reactGA?.event({
						category: gaCategories.search,
						action: gaEvents.search,
						label: values.searchTerm,
						nonInteraction: false
					})
					setSubmitting(false)
				}}
			>{() =>
					<Form onKeyDown={() => toggle(true)}
						className={"position-relative d-flex justify-items-center"}>
						{isLoading ? <div className={"position-absolute d-none d-md-block"}
							style={{top: 7, right: 16}}>
							<Spinner size="sm" />
						</div> : termValue || isOn || isBackgroundOn ? <button className={"position-absolute d-none d-md-block btn top-0 end-0 btn"}
							onClick={() => {
								setTermValue("")
								setValue(null)
								toggle(false)
								setIsBackgroundOn(false)
							}}>
							<X color={ isBrandsPage ? "#fff" : "#000"}
								width={"25px"}
								height={"25px"}/>
						</button> :
							<div className={"position-absolute d-none d-md-block"}
								style={{top: 7, right: 16}}>
								<Search size={18}
									color={ isBrandsPage ? "#fff" : "#000"} />
							</div>}

						{isLoading ? <div className={"position-absolute d-md-none"}
							style={{top: 7, right: 16}}>
							<Spinner size="sm" />
						</div> : termValue || isOn || isBackgroundOn ? <button className={"d-md-none position-absolute shadow-none btn top-0 end-0 btn"}
							onClick={() => {
								setTermValue("")
								setIsBackgroundOn(() => false)
								setValue(null)
								toggle(false)
							}}>
							<X width={"25px"}
								height={"25px"}/>
						</button> : <button className={"d-md-none position-absolute shadow-none btn top-0 end-0 btn"}
							onClick={() => {
								router.push(filterUrl({[urlParams.searchValue]: value, page: "-"}, null, null))
								setTermValue("")
								setValue(null)
								toggle(false)
							}}>

							<Search size={18}
								color={ isBrandsPage ? "#fff" : "#000"} />
						</button>}
						<Field value={termValue}
							onFocus={handleFocus}
							onChange={e => {
								setTermValue(e.target.value)
								handleOnChange(e.target.value)
							}}
							onKeyDown={handlePressEnterToSaveSearchValue}
							placeholder={t("search-placeholder")}
							innerRef={searchField}
							className={className || " w-100 form-control-lg border-0 bg-light search-field" }
							name={"searchTerm"}
							errors={errors}
							touched={touched}
						/>
					</Form>
				}
			</Formik>
			<div className="d-none d-md-block">
				{(isBackgroundOn && !value?.length) || value?.length < 3 ?
					<DynamicSearchContent isBackgroundOn={isBackgroundOn}
						setIsBackgroundOn={setIsBackgroundOn}/> : null}
			</div>
		</>
	)
}

SearchField.propTypes = {
	onStartTyping: PropTypes.func,
	onType: PropTypes.func,
	onEndTyping: PropTypes.func,
	className: PropTypes.string,
	setIsBackgroundOn: PropTypes.any,
	isBackgroundOn: PropTypes.bool,
	isBrandsPage: PropTypes.bool
}

export default SearchField
